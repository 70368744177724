.root {
    @apply px-4 flex justify-between w-full gap-8 mxl:gap-5 max-w-[1440px] mx-auto;
    @apply 2xl:max-w-[100vw] 2xl:mx-0;
}
.container {
    @apply flex w-full max-w-[280px] min-w-[250px] mxl:max-w-[200px] min-w-[200px] ;
}
.info{
    @apply w-full max-w-[550px] min-w-[400px] flex flex-col gap-2.5 tracking-wider flex-1 flex-shrink-0;
}
.info :global .hk_detail{
    @apply mxl:py-2;
}
.info :global .hk_detailHeading{
    @apply mxl:text-base;
}
.info :global .hk_detailsContainer{
    @apply gap-1;
}
.detailsWrap {
    @apply flex flex-col gap-2 w-4/12;
}
.img {
    @apply bg-grayLight h-full w-full max-w-[280px] bg-cover bg-center bg-grayLight bg-no-repeat pt-[80%];
}
.ul{
    @apply font-normal text-sm text-center italic text-gray-400 whitespace-normal;
}
.details {
  @apply flex flex-col gap-2;
}
.details button {
  @apply w-full lg:w-auto lg:flex-1;
}
.details .backButton{
    @apply lg:w-auto;
}
.dialogBody {
    @apply p-6 border-t border-solid border-secondary/20 cursor-pointer hover:bg-tableRowHover;
}
.dialogDetail {
    @apply flex gap-4 items-center;
}
.dialogImg {
    @apply w-20 h-20 object-contain;
}
.dialogText {
    @apply flex gap-2 flex-col text-secondary;
}
.dialogTitle {
    @apply text-base font-primary font-medium;
}
.dialogParagraph {
    @apply text-sm text-gray-500;
}
.detailButton{
    @apply h-14 mx-0 mxl:h-12 mb-0 mxl:m-0 md:text-xs;
}

/*If scroll is not there and we have data same as desktop  */
.root{
    /*@apply max-w-[100vw] sticky left-0;*/
}




/*If scroll is not there and we have limited data to show */
.root {
    @apply lg:grid lg:flex-wrap lg:justify-start lg:grid-cols-[280px_1fr] md:grid-cols-[200px_1fr] lg:gap-y-1.5 lg:gap-x-5 lg:items-start;
}
.details :global .text-padding-top-25{
    @apply lg:pt-0
}
.container {
    @apply lg:max-w-full;
}
.info{
    @apply lg:max-w-full mxl:min-w-[350px];
}
.detailsWrap {
    @apply lg:w-full;
}
.ul{
    @apply lg:text-xs;
}
.details {
    @apply lg:flex-row;
}
.info :global .hk_detailHeading{
    @apply pt-1.5;
}

@media screen and (max-width : 991px) {
    .root {
        grid-template-areas:'image content'
                            'image button';
    }
    .container {
        grid-area: image;
    }
    .info{
        grid-area: content;
    }
    .detailsWrap {
        grid-area: button;
    }
}
@media screen and (max-width : 830px) {
    .details{
        @apply lg:flex-wrap;
    }
    .details button{
        @apply min-w-[160px];
    }
}