.root {
    @apply border-b border-borderGray pt-5 max-w-site mx-auto w-full min-h-[60px] relative;
    @apply sticky top-0 z-[2] bg-white;
    /*@apply absolute top-0 left-0 right-0;*/
}
.nav {
  @apply -mb-px flex space-x-8 justify-center absolute w-full bottom-0;
}
:global(.sticky) .root{
    /*@apply bg-white z-[2] top-[60px] fixed;*/
}